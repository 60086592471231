import "core-js/stable";
import "regenerator-runtime/runtime";

import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import StatusBar from "@uppy/status-bar";
import FileInput from "@uppy/file-input";
import DropTarget from "@uppy/drop-target";
import German from "@uppy/locales/lib/de_DE";

const multipleFileUpload = (fileInput: HTMLInputElement) => {
  const formGroup = fileInput.parentNode as Element;
  if (!formGroup) return;
  formGroup!.removeChild(fileInput);

  const uppy = new Uppy({
    id: fileInput.id,
    autoProceed: true,
    locale: German,
    restrictions: {
      allowedFileTypes: fileInput.accept.split(","),
    },
  });

  uppy
    .use(XHRUpload, {
      endpoint: "/images/upload",
      formData: true,
      fieldName: "files[]",
    })
    .use(FileInput, {
      target: formGroup,
      locale: { strings: { chooseFiles: "Bilder hinzufügen" } },
    })
    .use(StatusBar, {
      target: document.querySelector(".uploadbutton") as Element,
    })
    .use(DropTarget, { target: document.body });

  uppy.on("upload-success", (_file, response) => {
    const hiddenField = document.createElement("input");
    if (!response.body) {
      console.error("No response body", response);
      airbrake.notify("No response body", { response });
      return;
    }
    const body = response.body;
    const use_signed = !!body.signed_data;
    const data = use_signed ? JSON.parse(atob(body.signed_data.split("--")[0])) : body.data;
    hiddenField.type = "hidden";
    hiddenField.name = `post[images_attributes][${data.id.replace(/\D/g, "")}][image]`;
    hiddenField.value = use_signed ? body.signed_data : body.data;
    document.querySelector("form .imagelist")!.insertAdjacentHTML(
      "beforeend",
      `<div class="p-1 relative cursor-grab">
                ${hiddenField.outerHTML}
                <img src="${response.uploadURL}" alt="" class="w-28 h-16 rounded object-contain">
                <div class="rm cursor-pointer absolute p-1 -top-2 -right-2 h-6 w-6 color-red-500 bg-white/75 backdrop-blur-sm hover:backdrop-blur-lg hover:scale-105 rounded leading-none before:content-['❌']" title="entfernen" onclick="this.parentElement.remove();return false"></div>
            </div>`,
    );
  });
};

const enableUppy = () => {
  document.querySelectorAll("input[type=file].use-uppy").forEach((fileInput) => {
    multipleFileUpload(fileInput as HTMLInputElement);
  });
};

document.addEventListener("turbo:load", enableUppy);
document.addEventListener("turbo:morph", enableUppy);
